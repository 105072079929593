import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../redux/actions/user'
import axios from 'axios'
import api from '../../api'
import { getAllSellers } from '../../redux/actions/seller'
import { getAllBrands } from '../../redux/actions/brand'
// import io from 'socket.io-client';
import ReactPaginate from 'react-paginate'
import { useReactToPrint } from 'react-to-print'
import { addTonotification } from '../../redux/actions/notufication'
import { useTranslation } from 'react-i18next'

const itemsPerPage = 20

// const socket = io.connect('http://54.237.232.111:5000');

export const options = {
  title: 'All Customer Levels',
  pieHole: 0.3,
}
export const optionsBrands = {
  title: 'All Brands',
  // pieHole: 0.3,
  bar: { groupWidth: '30%' },
}
const AdminBranchSummary = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const { users } = useSelector((state) => state.user);
  const { branches } = useSelector((state) => state.branches)
  const [customers, setCustomers] = useState([])

  // const { sellers } = useSelector((state) => state.seller);
  const { brands } = useSelector((state) => state.brands)
  const [allBrands, setAllBrands] = useState([])
  const [branch, setBranch] = useState('')
  const [branchId, setBranchId] = useState('')
  const [totalSum, setTotalSum] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)
  const conponentPDF = useRef()

  function getCountArray(arr) {
    const countMap = {}
    arr.forEach((item) => {
      if (countMap[item]) {
        countMap[item]++
      } else {
        countMap[item] = 1
      }
    })

    const elementArray = Object.keys(countMap)
    const countArray = Object.values(countMap)
    return { countArray, elementArray }
  }
  // const brandArray = [];
  // for (let i = 0; i <= getCountArray(allBrands).elementArray.length; i++) {
  //   console.log(getCountArray(allBrands).elementArray[i]);
  // }
  // for (let i = 0; i <= getCountArray(allBrands).countArray.length; i++) {
  //   console.log(getCountArray(allBrands).countArray[i]);
  // }

  useEffect(() => {
    api
      .get(`/customer/get-all-customer`, { withCredentials: true })
      .then((res) => {
        setCustomers(res.data.customers)
      })

    dispatch(getAllBrands())
  }, [dispatch, branchId])

  useEffect(() => {
    setAllBrands(customers?.flatMap((item) => item.selectBrands.name))
    getCountArray(allBrands)
    setTotalSum(
      customers &&
        customers
          .filter(
            (t) =>
              t.totalPrice > 0 &&
              t.levels[t.levels?.length - 1]?.status !== 'Canceled'
          )
          .reduce((acc, cust) => acc + cust.totalPrice, 0)
    )
  }, [customers, allBrands])

  useEffect(() => {
    customers &&
      customers?.map((customer) => {
        let filtered =
          customer.levels[customer.levels?.length - 1]?.status !== 'Complete'
        if (filtered) {
          let customerDate = new Date(
            customer.levels[customer.levels?.length - 1]?.updatedAt
          )
            .toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .split('/')
            .reverse()
            .join('-')

          const date1 = new Date(customerDate)
          const date2 = new Date()

          const timeDifference = date2.getTime() - date1.getTime()
          const differenceInDays = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
          )

          if (differenceInDays >= 3) {
            const notificationData = { ...customer }
            dispatch(addTonotification(notificationData))
          }
        }
      })
  }, [customers, dispatch])

  const handleSelect = (event) => {
    const value = event.target.value
    branches.filter((item) =>
      item.name === value ? setBranchId(item._id) : ''
    )
    setBranch(value)
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  const pageStyle = '@page { size: 1in 2in }'

  const offset = currentPage * itemsPerPage
  const paginatedData = brands?.slice(offset, offset + itemsPerPage)

  const handleSearchChange = () => {
    const filteredProducts = customers.filter((item) => {
      return item.branchId === branchId
    })
    setCustomers(filteredProducts)

    setTotalSum(
      customers &&
        customers
          .filter(
            (t) =>
              t.branchId === branchId &&
              t.totalPrice > 0 &&
              t.levels[t.levels?.length - 1]?.status !== 'Canceled'
          )
          .reduce((acc, cust) => acc + cust.totalPrice, 0)
    )
  }

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: 'All Customers',
    // pageStyle: { pageStyle },
    // onAfterPrint: () => alert('Data saved in PDF'),
  })

  return (
    <div className="w-full justify-center items-center p-1 px-2 mr-2 mt-[65px] ">
      <div className="flex justify-between items-center py-2 w-full border-b p-1 border-gray-200">
        <p className="px-1 text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[18px] font-[600]">
          {t('main.summary')}
        </p>
      </div>

      <div className="px-3 flex justify-between items-center w-full">
        <div className="relative w-full mt-2 mb-3  flex justify-start items-center">
          <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-state"
            // required
            value={branch}
            onChange={(e) => {
              handleSelect(e)
            }}
          >
            <option>{t('admin.select_branch')} </option>
            {branches &&
              branches?.map((item, index) => (
                <option key={index}>{item.name}</option>
              ))}
          </select>
          <div className="pointer-events-none absolute px-3  inset-y-0 right-0  flex items-center  text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <div className=" mt-2 mb-3 flex px-3  justify-center items-center">
          <button
            className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
            onClick={handleSearchChange}
          >
            {t('admin.search')}{' '}
          </button>
        </div>
        <div className=" w-full mt-2 mb-3 flex justify-end items-center">
          <button
            className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
            onClick={generatePDF}
          >
            {t('admin.print')}{' '}
          </button>
        </div>
      </div>
      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[10px] md:text-[16px] lg:text-[16px] xl:text-[16px] font-[600]">
              {t('admin.all_brands')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[600]">
              ({brands?.length}) {t('customer.brand')}{' '}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>ID</span>
                        </div>
                      </th> */}
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.brand_name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.total_brand')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.no_of_customers')}</span>
                        </div>
                      </th>

                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.completed_customers')}</span>
                        </div>
                      </th> */}
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.total_amount')}</span>
                        </div>
                      </th> */}
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm  font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center justify-end px-3 gap-x-3">
                          <span>Actions</span>
                        </div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200  dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        {/* <td className="px-4 py-42 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}
                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {customers
                                    ?.flatMap((brandItem) =>
                                      brandItem.selectBrands.filter(
                                        (item1) => item1.name === item.name
                                      )
                                    )
                                    .map((itemAmount) => itemAmount.amount)
                                    .reduce((x, y) => {
                                      return x + y
                                    }, 0)}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4   text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex  items-center gap-x-3">
                            <div className="flex justify-center items-center gap-x-2">
                              <div>
                                <h2 className="font-medium  text-gray-800 dark:text-white ">
                                  {
                                    customers?.flatMap((brandItem) =>
                                      brandItem.selectBrands.filter(
                                        (item1) => item1.name === item.name
                                      )
                                    ).length
                                  }
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        {/* <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {
                                    customers?.flatMap(
                                      (brandItem) =>
                                        brandItem.selectBrands.filter(
                                          (item1) => item1.name === item.name
                                        ) &&
                                        brandItem.levels[
                                          brandItem.levels.length - 1
                                        ]?.status === 'Complete'
                                    ).length
                                  }
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}
                        {/* <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  ${' '}
                                  {customers
                                    .filter(
                                      (t) =>
                                        t.engineeringId === item._id &&
                                        // t.totalPrice > 0 &&
                                        t.levels[t.levels?.length - 1]
                                          ?.status !== 'Canceled'
                                    )
                                    .reduce(
                                      (acc, cust) => acc + cust.totalPrice,
                                      0
                                    )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}

                        {/* <td className="px-4 py-4 text-sm whitespace-nowrap">
                          <div className="flex items-center justify-end px-3 gap-x-6 ">
                            <Link to={`/admin/customers/${item._id}`}>
                              <Button>
                                <BsEye size={22} className="" fill="#F4AA8F" />
                              </Button>
                            </Link>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <br />
        <div className="w-full border-b"></div> */}

        <div className="justify-center flex ">
          <div className="w-1/3 min-h-[52vh] py-2 rounded">
            <h3 className="text-[22px] rtl:text-[24px] font-Poppins text-center pb-1">
              {t('admin.total_contract')}{' '}
            </h3>
            <p className="w-full text-[26px] font-[600] text-[#137568] border border-[#F4AA8F] text-center ">
              $ {totalSum}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center -mt-40">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={2}
          pageCount={Math.ceil(branches?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>
    </div>
  )
}

export default AdminBranchSummary
