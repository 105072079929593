import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
// import { BiEdit } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  // deleteCustomer,
  getAllCustomersEng,
} from '../../redux/actions/customer';
import CustomerCard from '../Customer/CustomerCard';
import Cookies from 'js-cookie';
import { getAllCustomersBranch } from '../../redux/actions/branchCustomer';
import { useTranslation } from 'react-i18next';

const AllCustomers = () => {
  const { t } = useTranslation();
  let { state } = useLocation();
  const { customers } = useSelector((state) => state.customers);
  const { branchCustomers } = useSelector((state) => state.customerBranch);
  const { engineer } = useSelector((state) => state.engineer);
  const engBranchId = Cookies.get('branchId');
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchAllCustomers, setSearchAllCustomers] = useState([]);
  const [searchShow, setSearchShow] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if (engineer.role === 'BranchAdmin') {
      if (state.sellerId !== '') {
        // const filteredData = branchCustomers?.map((outerObj) => ({
        //   ...outerObj,
        //   levels: outerObj.levels?.filter(
        //     (innerObj) =>
        //       innerObj.status !== 'Contract' ||
        //       (innerObj.status !== 'Complete' && innerObj.status.length > 0)
        //   ),
        // }));
        // setAllCustomers(
        //   filteredData?.filter(
        //     (innerObj) =>
        //       innerObj.engineeringId === state.sellerId &&
        //       innerObj.levels?.length > 0
        //   )
        // );

        const isItemExists =
          branchCustomers &&
          branchCustomers?.filter((item) => {
            return (
              item.levels[item.levels?.length - 1]?.status !== 'Contract' &&
              item.levels[item.levels?.length - 1]?.status !== 'Canceled' &&
              item.levels[item.levels?.length - 1]?.status !== 'Complete' &&
              item.engineeringId === state.sellerId
            );
          });
        setAllCustomers(isItemExists);
        setSearchAllCustomers(isItemExists);
      } else {
        // const filteredData = branchCustomers?.map((outerObj) => ({
        //   ...outerObj,
        //   levels: outerObj.levels?.filter(
        //     (innerObj) =>
        //       innerObj.status !== 'Contract' ||
        //       (innerObj.status !== 'Complete' && innerObj.status.length > 0)
        //   ),
        // }));
        // setAllCustomers(
        //   filteredData?.filter((innerObj) => innerObj.levels?.length > 0)
        // );
        const isItemExists =
          branchCustomers &&
          branchCustomers.filter((item) => {
            return (
              item.levels[item.levels?.length - 1]?.status !== 'Contract' &&
              item.levels[item.levels?.length - 1]?.status !== 'Canceled' &&
              item.levels[item.levels?.length - 1]?.status !== 'Complete'
            );
          });
        setAllCustomers(isItemExists);
        setSearchAllCustomers(isItemExists);
      }
    } else {
      // const filteredData = customers?.map((outerObj) => ({
      //   ...outerObj.levels,
      //   outerObj: outerObj.levels?.filter(
      //     (innerObj) =>
      //       innerObj.status !== 'Contract' && innerObj.status.length > 0
      //   ),
      // }));
      // setAllCustomers(filteredData);
      // console.log(filteredData);
      // console.log(filteredData);
      const isItemExists =
        customers &&
        customers?.filter(
          (item) =>
            item.levels[item.levels?.length - 1]?.status !== 'Complete' &&
            item.levels[item.levels?.length - 1]?.status !== 'Canceled' &&
            item.levels[item.levels?.length - 1]?.status !== 'Contract'
        );

      setAllCustomers(isItemExists);
      setSearchAllCustomers(isItemExists);
    }
  }, [customers, branchCustomers, engineer.role]);

  useEffect(() => {
    dispatch(getAllCustomersEng(engineer._id));
    dispatch(getAllCustomersBranch(engBranchId));
  }, [dispatch, engineer._id, engBranchId]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      searchAllCustomers &&
      searchAllCustomers.filter((person) => {
        return (
          person.name.toLowerCase().includes(term.toLowerCase()) ||
          person.phone.toLowerCase().includes(term.toLowerCase())
        );
      });
    setAllCustomers(filteredProducts);
    // if (e.target.value === '') {
    //   setSearchShow(false);
    //   dispatch(getAllCustomersEng(seller._id));
    //   dispatch(getAllCustomersBranch(engBranchId));
    // } else {
    //   setSearchShow(true);
    //   setAllCustomers(filteredProducts);
    // }
  };

  return (
    // <>
    //   {isLoading ? (
    //     <Loader />
    //   ) : (
    <div className="container">
      <div className="relative  mt-[65px] mr-3 flex justify-between items-center border-b p-1 border-gray-200">
        <div className="flex justify-between items-center w-1/3">
          <p className="px-3 text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] font-[600]">
            {t('main.all_customers')}
          </p>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="relative flex w-full md:w-2/3 items-stretch">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto  ltr:rounded-l rtl:rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div
              className="relative z-[2] flex items-center ltr:rounded-r rtl:rounded-l bg-[#222222]    px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              // type="button"
              // id="button-addon1"
              // data-te-ripple-init
              // data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" />
              </svg>
            </div>
          </div>
          <div className="items-stretch mr-2">
            {/* <Link
              to="/dashboard/register/customer"
              className="w-full flex items-center px-2 rounded bg-[#F4AA8F] hover:bg-[#413632]  "
            >
              <div className="p-2">
                <span className="text-black  text-[12px] md:text-[14px] lg:text-[16px] xl:text-[16px]  font-[600] flex items-center hover:text-[#fff] ">
                  <AiOutlinePlus
                    size={20}
                    color={200}
                    className="mr-2 items-center"
                  />
                  {t('main.add_customer')}
                </span>
              </div>
            </Link> */}
          </div>
        </div>
      </div>

      <div className="container  grid p-3 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-3 gap-3">
        {allCustomers && allCustomers.length !== 0 && (
          <>
            {allCustomers &&
              allCustomers.map((item, index) => (
                <CustomerCard data={item} key={index} />
              ))}
          </>
        )}
      </div>
    </div>
    //   )}
    // </>
  );
};

export default AllCustomers;
