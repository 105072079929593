import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  createCustomer,
  getAllCustomersEng,
} from '../../redux/actions/customer'
import axios from 'axios'
import api from '../../api'
import { toast } from 'react-toastify'
import { getAllBrands } from '../../redux/actions/brand'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { getCurrentTime } from '../../../src/utils/timeConvertor'

const CreateCustomerSeller = () => {
  const { engineer } = useSelector((state) => state.engineer)
  const { brands } = useSelector((state) => state.brands)
  // const { branches } = useSelector((state) => state.branches);
  // const { customers, success, error } = useSelector((state) => state.customers);

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectBrands, setSelectBrands] = useState([])
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [status, setStatus] = useState('')
  // const [statusLevel, setStatusLevel] = useState([]);
  const [reason, setReason] = useState('')
  const [reasonDetail, setReasonDetail] = useState('')
  const [totalPrice, setTotalPrice] = useState(0)
  const [noties, setNoties] = useState('')
  const [color, setColor] = useState('')
  const [image, setImage] = useState()
  // const [branch, setBranch] = useState('');
  const [branchId, setBranchId] = useState('')
  const [engineerName, setEngineerName] = useState('')
  const [engineerId, setEngineerId] = useState('')
  const [allEngineer, setAllEngineer] = useState([])
  const [fileNo, setFileNo] = useState()

  const levels = [
    { name: 'Measurements', color: '#137568' },
    { name: 'Design', color: '#C27664' },
    { name: 'Discussion', color: '#635985' },
    { name: 'Hold', color: '#F6635C' },
    { name: 'Contract', color: '#F2921D' },
    { name: 'Implementation', color: '#0F6292' },
    { name: 'Complete', color: '#539165' },
    { name: 'Canceled', color: '#C51605' },
  ]
  const imageUrl = '../../../images/00.jpg'

  useEffect(() => {
    const engBranchId = Cookies.get('branchId')
    api
      .get(`/engineering/getAllEngineer`, { withCredentials: true })
      .then((res) => {
        setAllEngineer(
          res?.data.engineer.filter(
            (item) => item.branchId === engBranchId && item.role === 'Engineer'
          )
        )
        console.log(res)
      })
    dispatch(getAllCustomersEng(engineer._id))
    dispatch(getAllBrands())
  }, [])

  const handleCheck = (event) => {
    const value = event.target.value
    const isChecked = event.target.checked

    if (isChecked) {
      //Add checked item into checkList
      setSelectBrands([...selectBrands, value])
    } else {
      //Remove unchecked item from checkList
      const filteredList = selectBrands.filter((item) => item !== value)
      setSelectBrands(filteredList)
    }
  }

  // const handleSelect = (event) => {
  //   const value = event.target.value;
  //   const engBranchId = Cookies.get('branchId');
  //   setBranchId(engBranchId);
  //   // branches.filter((item) =>
  //   //   item.name === value ? setBranchId(item._id) : ''
  //   // );
  //   setBranch(value);
  // };

  const handleSelectEngineer = (event) => {
    const value = event.target.value
    const engBranchId = Cookies.get('branchId')
    setBranchId(engBranchId)

    allEngineer.filter((item) =>
      item.name === value ? setEngineerId(item._id) : ''
    )
    setEngineerName(value)
  }

  const handleCheckStatus = (event) => {
    const value = event.target.value
    const isChecked = event.target.checked
    setStatus(value)

    if (isChecked) {
      levels.filter((item) =>
        item.name === value ? setColor(item.color) : null
      )
    }
  }

  // Generate string of checked items
  const checkedItems = selectBrands.length
    ? selectBrands.reduce((total, item) => {
        return total + ', ' + item
      })
    : ''

  var isChecked = (item) =>
    selectBrands.includes(item) ? 'checked-item' : 'not-checked-item'

  const handleSendNotification = async () => {
    // handleTotalAmount()
    await api
      .put(
        `/engineering/update-engineer-notification/${engineerId}`,
        {
          customerName: name,
          engineerName: engineer.name,
        },
        { withCredentials: true }
      )
      .then((res) => {})
      .catch((error) => {
        toast.error(error.response.data.message)
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const createdAt = getCurrentTime()
    setImage(imageUrl)
    const customerLevel = {
      status,
      color,
      reasonDetail,
      updatedAt: createdAt,
    }

    const selectedBrandWithAmount = selectBrands.map((item) => {
      return { name: item, amount: 0, selected: true }
    })
    // const createdAt = getCurrentTime();

    const newForm = {
      name,
      address,
      fileNo,
      phone,
      branchId: branchId,
      selectBrands: selectedBrandWithAmount,
      reason,
      totalPrice,
      noties,
      image,
      customerLevel,
      engineeringId: engineerId,
      engineering: engineerName,
      createdAt,
    }
    dispatch(createCustomer(newForm))
    toast.success('Customer info added succesfully!')
    handleSendNotification()
    navigate('/dashbord/createCustomerSeller')
    // window.location.reload();
    dispatch(getAllCustomersEng(engineer._id))
    setName('')
    setAddress('')
    setPhone('')
    setSelectBrands('')
    setFileNo('')
    // setImage('');
    setReason('')
    setTotalPrice('')
    setReasonDetail('')
    setNoties('')
  }

  return (
    <div className="container">
      <div className="flex justify-between mr-3 items-center border-b p-2 border-gray-200">
        <p className="px-3 md:px-2 text-[18px] font-[600] mt-[70px]">
          {t('main.add_customer')}
        </p>
        <p className="px-3 text-[16px] font-[500] mt-[70px]"></p>
      </div>

      <div className="container ">
        {/* create product form */}
        <form onSubmit={handleSubmit} className="">
          <div className=" grid p-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-8">
            <div className="-mx-3 mb-6 px-4 w-full">
              <div className="flex flex-wrap justify-center items-center -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.name')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="name"
                    type="text"
                    required
                    placeholder="Customer Name"
                    name="name"
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.phone')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="phone"
                    type="text"
                    required
                    placeholder="Customer Phone"
                    name="phone"
                    value={phone || ''}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.address')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="address"
                    type="text"
                    required
                    placeholder="Customer address"
                    name="address"
                    value={address || ''}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.fileNo')}
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="fileNo"
                    type="text"
                    autoComplete="off"
                    required
                    // disabled
                    placeholder="Customer file Number"
                    name="fileNo"
                    value={fileNo || ''}
                    onChange={(e) => setFileNo(e.target.value)}
                  />
                </div>
              </div>
              <div className=" px-3 -mx-3">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.brand')}
                </label>
                {brands?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full md:w-1/3 justify-center px-3 py-1 mb-6 md:mb-3 lg:mb-4 xl:mb-4 border"
                  >
                    <input
                      checked={
                        selectBrands && selectBrands.includes(item.name)
                          ? true
                          : false
                      }
                      id="checkbox"
                      type="checkbox"
                      value={item.name}
                      onChange={handleCheck}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      className={`${isChecked(
                        item.name
                      )} w-full py-3 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                    >
                      {item.name}
                    </label>
                  </div>
                ))}

                <div>
                  {t('customer.Items_selected_are')}:{'  '}
                  {`${checkedItems}`}
                </div>
              </div>
            </div>
            <div className="flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.status')}
                </label>

                <div>
                  {/* {levels &&
                    levels.map((item, index) => ( */}
                  <div
                    // key={index}
                    className="flex items-center justify-between w-full  px-3 mb-3 md:mb-0"
                  >
                    <div className="flex items-center w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-3 lg:mb-4 xl:mb-4 border">
                      <input
                        // disabled={
                        //   statusLevel
                        //     .map((i) => i.status)
                        //     .includes(item[0].name)
                        //     ? true
                        //     : false
                        // }
                        // checked={
                        //   statusLevel
                        //     .filter((i) => i.status)
                        //     .includes(item[0].name)
                        //     ? true
                        //     : null
                        // }
                        id="measure"
                        type="checkbox"
                        value="Measurements"
                        onChange={handleCheckStatus}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        className={` w-full py-1 px-2 text-md font-medium text-gray-900 dark:text-gray-300`}
                      >
                        {t('main.measurements')}
                      </label>
                    </div>
                    {/* <div className="flex items-center w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-3 lg:mb-4 xl:mb-4">
                          {statusLevel.map((i) =>
                            i.status === item.name
                              ? i.updatedAt.slice(0, 10)
                              : null
                          )}
                        </div> */}
                  </div>
                  {/* ))} */}
                </div>
              </div>

              {/* <div className="w-full md:w-2/3 px-3  mt-4 mb-6 md:mb-0 ">
                <div>
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.branch')}
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      required
                      value={branch}
                      onChange={(e) => {
                        handleSelect(e);
                      }}
                    >
                      <option>Select Branch</option>
                      {branches &&
                        branches.map((item, index) => (
                          <option key={index}>{item.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="w-full md:w-2/3 px-3  mt-4 mb-6 md:mb-0 ">
                <div>
                  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                    {t('customer.enginner')}
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      required
                      value={engineerName}
                      onChange={(e) => {
                        handleSelectEngineer(e)
                      }}
                    >
                      <option>{t('customer.select-engineer')}</option>
                      {allEngineer &&
                        allEngineer?.map((item, index) => (
                          <option key={index}>{item.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-2/3 px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                  {t('customer.note')}
                </label>
                <textarea
                  rows="5"
                  name="noties"
                  value={noties || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write note here..."
                  onChange={(e) => setNoties(e.target.value)}
                ></textarea>
              </div>
              <div>
                <br />
                <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                  <input
                    type="submit"
                    value="Create"
                    className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateCustomerSeller
