import React, { useEffect, useRef, useState } from 'react'
import {
  AiOutlineAntDesign,
  AiOutlineAppstoreAdd,
  AiOutlineBuild,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineStop,
} from 'react-icons/ai'
import {
  BsChatRightText,
  BsCheck2Circle,
  BsFillArrowRightCircleFill,
  BsXCircle,
} from 'react-icons/bs'
import styles from '../../styles/styles'
import { Link } from 'react-router-dom'
import { FaUsers, FaRegNewspaper, FaWarehouse } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../redux/actions/user'
import { BiRuler } from 'react-icons/bi'
import Chart from 'react-google-charts'
import axios from 'axios'
import { getAllSellers } from '../../redux/actions/seller'
import { getAllBrands } from '../../redux/actions/brand'
// import io from 'socket.io-client';
import ReactPaginate from 'react-paginate'
import { useReactToPrint } from 'react-to-print'
import { addTonotification } from '../../redux/actions/notufication'
import { useTranslation } from 'react-i18next'
import { getAllCustomersAdmin } from '../../redux/actions/admin'
import { getAllStorages } from '../../redux/actions/storage'
import api from '../../api'

const itemsPerPage = 20

// const socket = io.connect('http://54.237.232.111:5000');

export const options = {
  title: 'All Customer Levels',
  pieHole: 0.3,
}
export const optionsBrands = {
  title: 'All Brands',
  // pieHole: 0.3,
  bar: { groupWidth: '30%' },
}
const AdminDashboardMain = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.user)
  const { branches } = useSelector((state) => state.branches)
  const [customers, setCustomers] = useState([])
  const { sellers } = useSelector((state) => state.seller)
  const { storages } = useSelector((state) => state.storages)
  const [allBrands, setAllBrands] = useState([])
  const [branch, setBranch] = useState('')
  const [branchId, setBranchId] = useState('')
  const [sellerId, setSellerId] = useState('')
  const [seller, setSeller] = useState('')
  const [allSellers, setAllSellers] = useState([])
  const [allSellersBranch, setAllSellersBranch] = useState([])
  const [allCustomersLength, setAllCustomersLength] = useState([])
  const [allContracts, setAllContracts] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const conponentPDF = useRef()

  function getCountArray(arr) {
    const countMap = {}
    arr.forEach((item) => {
      if (countMap[item]) {
        countMap[item]++
      } else {
        countMap[item] = 1
      }
    })

    const elementArray = Object.keys(countMap)
    const countArray = Object.values(countMap)
    return { countArray, elementArray }
  }
  // const brandArray = [];
  // for (let i = 0; i <= getCountArray(allBrands).elementArray.length; i++) {
  //   console.log(getCountArray(allBrands).elementArray[i]);
  // }
  // for (let i = 0; i <= getCountArray(allBrands).countArray.length; i++) {
  //   console.log(getCountArray(allBrands).countArray[i]);
  // }
  //

  useEffect(() => {
    api
      .get(`/customer/get-all-customer`, { withCredentials: true })
      .then((res) => {
        setCustomers(res.data.customers)
        const isItemExists =
          res.data.customers &&
          res.data.customers?.filter((item) => {
            return (
              item.levels[item.levels?.length - 1]?.status !== 'Contract' &&
              item.levels[item.levels?.length - 1]?.status !== 'Canceled' &&
              item.levels[item.levels?.length - 1]?.status !== 'Complete'
            )
          })
        setAllCustomersLength(isItemExists)
      })

    api
      .get(`/engineering/admin-all-sellers`, { withCredentials: true })
      .then((res) => {
        setAllSellers(
          res.data.sellers.filter((item) => {
            return item.role !== 'BranchAdmin' && item.role !== 'Recieptionist'
          })
        )
        setAllSellersBranch(
          res.data.sellers.filter((item) => {
            return item.role !== 'BranchAdmin' && item.role !== 'Recieptionist'
          })
        )
      })

    dispatch(getAllCustomersAdmin())
    dispatch(getAllUsers())
    dispatch(getAllSellers())
    dispatch(getAllBrands())
    dispatch(getAllStorages())
  }, [dispatch])

  useEffect(() => {
    setAllBrands(customers?.flatMap((item) => item.selectBrands.name))
    getCountArray(allBrands)
  }, [])

  useEffect(() => {
    customers &&
      customers?.map((customer) => {
        let filtered =
          customer.levels[customer.levels?.length - 1]?.status !== 'Complete'
        if (filtered) {
          let customerDate = new Date(
            customer.levels[customer.levels?.length - 1]?.updatedAt
          )
            .toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .split('/')
            .reverse()
            .join('-')

          const date1 = new Date(customerDate)
          const date2 = new Date()

          const timeDifference = date2.getTime() - date1.getTime()
          const differenceInDays = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
          )

          if (differenceInDays >= 3) {
            const notificationData = { ...customer }
            dispatch(addTonotification(notificationData))
          }
        }
      })

    const condition = (item) => item.status === 'Contract'
    const filteredElements = customers
      .map((obj) => ({
        id: obj._id,
        levels: obj.levels.filter(condition),
      }))
      .filter((obj) => obj.levels.length > 0)
    setAllContracts(filteredElements.length)
  }, [customers, dispatch])

  const handleSelect = (event) => {
    const value = event.target.value
    branches.filter((item) =>
      item.name === value ? setBranchId(item._id) : ''
    )
    setBranch(value)
    //get All seller
    // const filteredProducts =
    //   sellers &&
    //   sellers.filter((item) => {
    //     return (
    //       item.branchId === branchId &&
    //       item.role !== 'BranchAdmin' &&
    //       item.role !== 'Recieptionist'
    //     );
    //   });
    // setAllSellersBranch(filteredProducts);
    // setAllSellers(filteredProducts);
  }

  // const handleSearchChange = () => {
  //   const filteredProducts =
  //     sellers &&
  //     sellers.filter((item) => {
  //       return item.branchId === branchId;
  //     });

  //   setAllSellers(filteredProducts);
  //   // }
  // };

  const handleSearchAllBranch = () => {
    api
      .get(`/customer/get-all-customer`, { withCredentials: true })
      .then((res) => {
        setCustomers(
          res.data.customers.filter((item) => {
            return item.branchId === branchId
          })
        )
      })
    //get All seller
    const filteredProducts =
      sellers &&
      sellers.filter((item) => {
        return (
          item.branchId === branchId &&
          item.role !== 'BranchAdmin' &&
          item.role !== 'Recieptionist'
        )
      })
    setAllSellersBranch(filteredProducts)
    setAllSellers(filteredProducts)
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  const pageStyle = '@page { size: 1in 2in }'

  const offset = currentPage * itemsPerPage
  const paginatedData = allSellers?.slice(offset, offset + itemsPerPage)

  // const pageStyle = '@page { size: 1in 2in }';

  // const offset = currentPage * itemsPerPage;
  // const paginatedData = allSellers?.slice(offset, offset + itemsPerPage);
  //setSelectCustomer
  const handleSelectEngineer = (event) => {
    const value = event.target.value
    sellers.filter((item) => (item.name === value ? setSellerId(item._id) : ''))
    setSeller(value)
  }

  const handleSearchEngineer = () => {
    api
      .get(`/customer/get-all-customer`, { withCredentials: true })
      .then((res) => {
        setCustomers(
          res.data.customers.filter((item) => {
            return item.engineeringId === sellerId
          })
        )

        const isItemExists =
          res.data.customers &&
          res.data.customers?.filter((item) => {
            return (
              item.levels[item.levels?.length - 1]?.status !== 'Contract' &&
              item.levels[item.levels?.length - 1]?.status !== 'Canceled' &&
              item.levels[item.levels?.length - 1]?.status !== 'Complete' &&
              item.engineeringId === sellerId
            )
          })
        setAllCustomersLength(isItemExists)
      })

    const filteredProducts =
      sellers &&
      sellers.filter((item) => {
        return item._id === sellerId
      })

    setAllSellers(filteredProducts)
  }
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: 'All Customers',
    // pageStyle: { pageStyle },
    // onAfterPrint: () => alert('Data saved in PDF'),
  })

  const data = [
    ['Levels', 'Customer'],
    // ['All', customers.length],
    [
      'Measurements',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Measurements'
      ).length,
    ],
    [
      'Design',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Design'
      ).length,
    ],
    [
      'Discussion',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Discussion'
      ).length,
    ],
    [
      'Hold',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Hold'
      ).length,
    ],
    ['Contract', allContracts],
    // [
    //   'Contract',
    //   customers.filter(
    //     (item) => item.levels[item.levels.length - 1]?.status === 'Contract'
    //   ).length,
    // ],
    [
      'Stop Orders',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Stop Orders'
      ).length,
    ],
    [
      'Storage',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Storage'
      ).length,
    ],
    [
      'Implementation',
      customers.filter(
        (item) =>
          item.levels[item.levels.length - 1]?.status === 'Implementation'
      ).length,
    ],
    [
      'Complete',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Complete'
      ).length,
    ],
    [
      'Canceled',
      customers.filter(
        (item) => item.levels[item.levels.length - 1]?.status === 'Canceled'
      ).length,
    ],
  ]

  const row = []

  sellers &&
    sellers.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        customer: customers.filter((i) => i.engineeringId === item._id).length,
        branch: item.branch,
        complete: customers.filter(
          (c) =>
            c.engineeringId === item._id &&
            c.levels[c.levels.length - 1]?.status === 'Complete'
        ).length,
        amount: customers
          .filter(
            (t) =>
              t.engineeringId === item._id &&
              t.totalPrice > 0 &&
              t.levels[t.levels?.length - 1]?.status !== 'Canceled'
          )
          .reduce((acc, cust) => acc + cust.totalPrice, 0),
      })
    })
  return (
    <div className="w-full justify-center items-center p-1 px-2 mr-2 mt-[65px] ">
      <div className="flex justify-between items-center py-2 w-full border-b p-1 border-gray-200">
        <p className="px-1 text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[18px] font-[600]">
          {t('main.overview')}
        </p>
      </div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-3 gap-3">
        <div className="px-2 flex justify-between items-center w-full">
          <div className="relative w-full   flex items-center">
            <div className="relative w-60">
              <p className="px-2 flex justify-between items-center">
                {t('admin.select_branch')}
              </p>
            </div>
            <div className="relative w-full">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                // required
                value={branch}
                onChange={(e) => {
                  handleSelect(e)
                }}
              >
                <option>{t('admin.select_branch')} </option>
                {branches &&
                  branches?.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))}
              </select>
              <div className="pointer-events-none absolute px-2  inset-y-0 right-0  flex items-center  text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className=" flex px-3  justify-center items-center">
              <button
                className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
                onClick={handleSearchAllBranch}
              >
                {t('admin.search')}{' '}
              </button>
            </div>
          </div>
        </div>
        <div className=" w-full mt-2 mb-3 px-2 flex justify-end items-center">
          <div className="relative w-full flex  items-center">
            <div className="relative w-60">
              <p className="px-2 flex">{t('customer.select-engineer')}</p>
            </div>
            <div className="relative w-full">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                // required
                value={seller}
                onChange={(e) => {
                  handleSelectEngineer(e)
                }}
              >
                <option>{t('customer.select-engineer')} </option>
                {allSellersBranch &&
                  allSellersBranch?.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))}
              </select>
              <div className="pointer-events-none absolute px-3  inset-y-0 right-0  flex items-center  text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="  flex px-3  justify-center items-center">
            <button
              className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
              onClick={handleSearchEngineer}
            >
              {t('admin.search')}{' '}
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 pt-3 gap-3">
        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <FaUsers size={30} className="mr-2" fill="#413632" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('admin.all_cstomers')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {allCustomersLength && allCustomersLength.length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to={`/admin/customers`}
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_customers')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.customers')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <BiRuler size={30} className="mr-2" fill="#137568" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.measurements')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status ===
                    'Measurements'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/measurements"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_measurements')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.measurements')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <AiOutlineAntDesign size={30} className="mr-2" fill="#C27664" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.design')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Design'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/designs"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_design')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.design')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <BsChatRightText size={30} className="mr-2" fill="#635985" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.discussion')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Discussion'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/discussions"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_discussion')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.discussion')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <FaRegNewspaper size={30} className="mr-2" fill="#F6635C" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.holding')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Hold'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/holdings"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_holding')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.holding')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <FaRegNewspaper size={30} className="mr-2" fill="#F2921D" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.contract')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {allContracts}
              {/* customers &&
                  customers?.map((outerObj) => ({
                    ...outerObj,
                    levels: outerObj.levels?.filter(
                      (innerObj) =>
                        innerObj.status === 'Contract
                        // innerObj.status !== 'Canceled' &&
                        // innerObj.status.length > 0
                    ),
                  })).length
                setAllCustomers(
                  filteredData?.filter((innerObj) => innerObj.levels?.length > 0)
                );

                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Contract'
                ).length
              } */}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/contracts"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_contract')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.contract')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <AiOutlineStop size={30} className="mr-2" fill="#C51605" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.stop_order')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status ===
                    'Stop Orders'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/stop-orders"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_stop_order')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.stop_order')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <AiOutlineAppstoreAdd size={30} className="mr-2" fill="#137568" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.store')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Storage'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link to="/admin/store" state={{ All: 'All', sellerId, branchId }}>
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_store')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.store')}
              </h5>
            )} */}
          </div>
        </div>

        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <AiOutlineBuild size={30} className="mr-2" fill="#0F6292" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.implementations')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status ===
                    'Implementation'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/implementations"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_implementations')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.implementations')}
              </h5>
            )} */}
          </div>
        </div>
        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <BsCheck2Circle size={30} className="mr-2" fill="#539165" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.completed')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Complete'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/completes"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_completed')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.completed')}
              </h5>
            )} */}
          </div>
        </div>
        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <BsXCircle size={30} className="mr-2" fill="#C51605" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.canceled')}{' '}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {customers &&
                customers.filter(
                  (item) =>
                    item.levels[item.levels.length - 1]?.status === 'Canceled'
                ).length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link
              to="/admin/canceled"
              state={{ All: 'All', sellerId, branchId }}
            >
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_canceled')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.canceled')}
              </h5>
            )} */}
          </div>
        </div>
        <div className="flex-wrap items-center justify-center rounded border-gray-300  bg-[#F1F2F7]  border-1">
          <div className="flex items-center px-2 py-2">
            <FaWarehouse size={30} className="mr-2" fill="#F2921D" />
            <h3
              className={`${styles.productTitle} px-2 !text-[18px] rtl:!text-[20px] leading-5 !font-[400] text-gray-500`}
            >
              {t('main.mainstorage')}
            </h3>
          </div>
          <div className="flex justify-end items-center px-2 mr-4">
            <h5 className="mb-2  pl-[36px] text-[22px] font-[600] text-[#413632]">
              {storages && storages.length}
            </h5>
          </div>
          <div className="w-full  flex-end block bg-[#404041] ">
            {/* {user && user.role === 'Admin' ? ( */}
            <Link to="/admin/storageorders">
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.view_main_store')}
                <BsFillArrowRightCircleFill
                  className="ml-2 rtl:mr-2 rtl:-scale-x-100"
                  fill="#F4AA8F"
                />
              </h5>
            </Link>
            {/* ) : (
              <h5 className="flex w-full p-1 items-center justify-center text-[#fff]">
                {t('main.store')}
              </h5>
            )} */}
          </div>
        </div>
      </div>

      <br />
      <div className="w-full border-b"></div>

      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  gap-6">
        <div className="w-full min-h-[40vh] rounded">
          <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
        <div className="w-full min-h-[40vh] py-10 rounded">
          <h3 className="text-[22px] rtl:text-[24px] font-Poppins text-center pb-1">
            {t('admin.total_contract')}{' '}
          </h3>
          <p className="w-full text-[26px] font-[600] text-[#137568] border border-[#F4AA8F] text-center ">
            ${' '}
            {
              customers &&
                customers
                  .filter(
                    (t) =>
                      t.totalPrice > 0 &&
                      t.levels[t.levels?.length - 1]?.status !== 'Canceled'
                  )
                  .reduce((acc, cust) => acc + cust.totalPrice, 0)
              // customers.reduce((acc, item) => acc + item.totalPrice, 0)
            }
          </p>
          {/* <Chart
            chartType="ColumnChart"
            width="100%"
            height="350px"
            data={dataBrands}
            options={optionsBrands}
          /> */}
        </div>
      </div>

      {/* <div className="flex w-full  items-center justify-center">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div> */}

      <div className="px-3 flex justify-between items-center w-full">
        <div className="relative w-full mt-2 mb-3  flex justify-start items-center">
          {/* <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-state"
            // required
            value={branch}
            onChange={(e) => {
              handleSelect(e);
            }}
          >
            <option>{t('admin.select_branch')} </option>
            {branches &&
              branches?.map((item, index) => (
                <option key={index}>{item.name}</option>
              ))}
          </select>
          <div className="pointer-events-none absolute px-3  inset-y-0 right-0  flex items-center  text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div> */}
        </div>
        <div className=" mt-2 mb-3 flex px-3  justify-center items-center">
          {/* <button
            className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
            onClick={handleSearchChange}
          >
            {t('admin.search')}{' '}
          </button> */}
        </div>
        <div className=" w-full mt-2 mb-3 flex justify-end items-center">
          <button
            className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
            onClick={generatePDF}
          >
            {t('admin.print')}{' '}
          </button>
        </div>
      </div>
      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[10px] md:text-[16px] lg:text-[16px] xl:text-[16px] font-[600]">
              {t('main.all_customers')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[600]">
              ({allSellers?.length}) {t('admin.customer')}{' '}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>ID</span>
                        </div>
                      </th> */}
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.engineer_name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.branch')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.no_of_customers')}</span>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.completed_customers')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.total_amount')}</span>
                        </div>
                      </th>
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm  font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center justify-end px-3 gap-x-3">
                          <span>Actions</span>
                        </div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200  dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        {/* <td className="px-4 py-42 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}
                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.branch}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4   text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex  items-center gap-x-3">
                            <div className="flex justify-center items-center gap-x-2">
                              <div>
                                <h2 className="font-medium  text-gray-800 dark:text-white ">
                                  {
                                    customers.filter(
                                      (i) => i.engineeringId === item._id
                                    ).length
                                  }
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {
                                    customers.filter(
                                      (c) =>
                                        c.engineeringId === item._id &&
                                        c.levels[c.levels.length - 1]
                                          ?.status === 'Complete'
                                    ).length
                                  }
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  ${' '}
                                  {customers
                                    .filter(
                                      (t) =>
                                        t.engineeringId === item._id &&
                                        // t.totalPrice > 0 &&
                                        t.levels[t.levels?.length - 1]
                                          ?.status !== 'Canceled'
                                    )
                                    .reduce(
                                      (acc, cust) => acc + cust.totalPrice,
                                      0
                                    )}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        {/* <td className="px-4 py-4 text-sm whitespace-nowrap">
                          <div className="flex items-center justify-end px-3 gap-x-6 ">
                            <Link to={`/admin/customers/${item._id}`}>
                              <Button>
                                <BsEye size={22} className="" fill="#F4AA8F" />
                              </Button>
                            </Link>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={2}
          pageCount={Math.ceil(sellers?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>
    </div>
  )
}

export default AdminDashboardMain
