import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
}

export const userReducer = createReducer(initialState, {
  LoginUserRequest: (state) => {
    state.loading = true
  },
  LoginUserSuccess: (state, action) => {
    state.isAuthenticated = true
    state.loading = false
    state.user = action.payload
  },
  LoginUserFail: (state, action) => {
    state.loading = false
    state.error = action.payload
    state.isAuthenticated = false
  },

  LoadUserRequest: (state) => {
    state.loading = true
  },
  LoadUserSuccess: (state, action) => {
    state.isAuthenticated = true
    state.loading = false
    state.user = action.payload
  },
  LoadUserFail: (state, action) => {
    state.loading = false
    state.error = action.payload
    state.isAuthenticated = false
  },

  // LoadSellerRequest: (state) => {
  //   state.isLoading = true
  // },
  // LoadSellerSuccess: (state, action) => {
  //   state.isSeller = true
  //   state.isLoading = false
  //   state.seller = action.payload

  // },
  // LoadSellerFail: (state, action) => {
  //   state.isLoading = false
  //   state.error = action.payload
  //   state.isSeller = false
  // },

  // update user information
  updateUserInfoRequest: (state) => {
    state.loading = true
  },
  updateUserInfoSuccess: (state, action) => {
    state.loading = false
    state.user = action.payload
  },
  updateUserInfoFailed: (state, action) => {
    state.loading = false
    state.error = action.payload
  },

  // update user address
  updateUserAddressRequest: (state) => {
    state.addressloading = true
  },
  updateUserAddressSuccess: (state, action) => {
    state.addressloading = false
    state.successMessage = action.payload.successMessage
    state.user = action.payload.user
  },
  updateUserAddressFailed: (state, action) => {
    state.addressloading = false
    state.error = action.payload
  },

  // delete user address
  deleteUserAddressRequest: (state) => {
    state.addressloading = true
  },
  deleteUserAddressSuccess: (state, action) => {
    state.addressloading = false
    state.successMessage = action.payload.successMessage
    state.user = action.payload.user
  },
  deleteUserAddressFailed: (state, action) => {
    state.addressloading = false
    state.error = action.payload
  },

  // get all users --- admin
  getAllUsersRequest: (state) => {
    state.usersLoading = true
  },
  getAllUsersSuccess: (state, action) => {
    state.usersLoading = false
    state.users = action.payload
  },
  getAllUsersFailed: (state, action) => {
    state.usersLoading = false
    state.error = action.payload
  },
  clearErrors: (state) => {
    state.error = null
  },
  clearMessages: (state) => {
    state.successMessage = null
  },
})
