import axios from 'axios'
import api from '../../api'

// get All Customers of an eng
export const getAllCustomersBranch = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllCustomersBranchRequest',
    })

    const { data } = await api.get(
      `/customer/get-all-customer-branch/${id}`
    )
    dispatch({
      type: 'getAllCustomersBranchSuccess',
      payload: data.customers,
    })
  } catch (error) {
    dispatch({
      type: 'getAllCustomersBranchFailed',
      payload: error.response.data.message,
    })
  }
}
