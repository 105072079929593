import React, { useEffect } from 'react'
import { RxCross1 } from 'react-icons/rx'
import styles from '../../styles/styles'
import { useSelector } from 'react-redux'
import axios from 'axios'
import api from '../../api'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const CustomerNotification = ({ setCustNote, sellerUnseenNotes }) => {
  const { engineer } = useSelector((state) => state.engineer)
  const { t } = useTranslation()

  const handleClear = async () => {
    await api
      .put(`/engineering/clear-engineer-notification/${engineer._id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setCustNote(false)
        window.location.reload(true)
        // dispatch(addTonotification(seller.unseenNotifications));
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      })
  }

  return (
    <div className="fixed top-0   w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0   rtl:left-0 ltr:right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {sellerUnseenNotes && sellerUnseenNotes?.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-1 ltr:right-3  rtl:left-6">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setCustNote(false)}
              />
            </div>
            <h5>{t('customer.notification_empty')}</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setCustNote(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} px-2 py-2`}>
                {/* <IoBagHandleOutline size={25} /> */}
                <h5 className="pl-2 text-[20px] font-[500]">
                  {sellerUnseenNotes?.length} {t('customer.customer_added')}
                </h5>
              </div>
              <div className="relative px-2 flex justify-end w-full">
                <button
                  className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
                  onClick={handleClear}
                >
                  {t('customer.delete_all')}
                </button>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {sellerUnseenNotes &&
                  sellerUnseenNotes.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      // quantityChangeHandler={quantityChangeHandler}
                      // removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const CartSingle = ({ data, removeFromCartHandler }) => {
  return (
    <div className="border-b px-3 py-3">
      <div className="flex justify-end items-center">
        {/* <RxCross1
          size={18}
          className="cursor-pointer"
          //  onClick={() => removeFromCartHandler(data)}
        /> */}
      </div>
      <div className="w-full flex items-center">
        <div className="pl-[5px]">
          <h1>{data.message}</h1>
        </div>
      </div>
    </div>
  )
}

export default CustomerNotification
