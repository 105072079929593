import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getAllCustomersEng } from '../../redux/actions/customer'
import axios from 'axios'
import api from '../../api'
import { backend_url } from '../../server'
import { getAllBrands } from '../../redux/actions/brand'
import Cookies from 'js-cookie'
import { getAllCustomersBranch } from '../../redux/actions/branchCustomer'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const TransferCustomer = () => {
  const { t } = useTranslation()
  const { seller } = useSelector((state) => state.seller)
  const { brands, isLoading } = useSelector((state) => state.brands)
  const { branchCustomers } = useSelector((state) => state.customerBranch)
  const { customers, success, error } = useSelector((state) => state.customers)
  const engBranchId = Cookies.get('branchId')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [update, setUpdate] = useState(false)
  const { id: customerId } = useParams()
  const [selectBrands, setSelectBrands] = useState([])
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [status, setStatus] = useState('')
  const [reason, setReason] = useState('')

  const [oldEngName, setOldEngName] = useState('')

  const [level, setLevel] = useState([])
  const [image, setImage] = useState()
  const [engineer, setEngineer] = useState('')
  const [engineerId, setEngineerId] = useState('')
  const [allEngineer, setAllEngineer] = useState([])

  const cancelList = ['COST', 'TIME', 'OTHERS']

  useEffect(() => {
    api
      .get(`/engineering/getAllEngineer-branch/${engBranchId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setAllEngineer(
          res.data.engineer.filter((item) => {
            return (
              item.branchId === engBranchId &&
              item.role !== 'BranchAdmin' &&
              item.role !== 'Recieptionist'
            )
          })
        )
      })
    const isItemExists =
      branchCustomers && branchCustomers.find((i) => i._id === customerId)

    if (isItemExists) {
      branchCustomers &&
        branchCustomers.find((i) =>
          i._id === customerId ? setLevel(i.levels) : ''
        )

      setUpdate(true)
      setName(isItemExists.name)
      setAddress(isItemExists.address)
      setPhone(isItemExists.phone)
      setSelectBrands(isItemExists.selectBrands)
      setStatus(isItemExists.levels)
      setReason(isItemExists.reason)
      setOldEngName(isItemExists.engineering)
      setImage(`${backend_url}${isItemExists.image}`)
    }
  }, [customers, branchCustomers])

  useEffect(() => {
    dispatch(getAllBrands())
    dispatch(getAllCustomersBranch(engBranchId))
  }, [dispatch])

  const handleSelectEngineer = (event) => {
    const value = event.target.value

    allEngineer.filter((item) =>
      item.name === value ? setEngineerId(item._id) : ''
    )
    setEngineer(value)
  }

  // Generate string of checked items
  const checkedItems = selectBrands.map((item1) => item1.name).length
    ? selectBrands
        .map((item1) => item1.name)
        .reduce((total, item) => {
          return total + ', ' + item
        })
    : ''

  var isChecked = (item) =>
    selectBrands.map((item1) => item1.name).includes(item.name)
      ? 'checked-item'
      : 'not-checked-item'

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (customerId) {
      await api
        .put(
          `/customer/transfer-customer-info/${customerId}`,
          {
            engineeringId: engineerId,
            engineering: engineer,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success('Customer info transfer succesfully!')
          dispatch(getAllCustomersBranch(engBranchId))
          navigate('/dashboard/customers', { state: { sellerId: '' } })
        })
        .catch((error) => {
          toast.error(error)
        })
    }
  }

  return (
    <div className="container">
      <div className="flex justify-between mr-3 items-center border-b p-2 border-gray-200">
        <p className="px-3 md:px-2 text-[16px] font-[500] mt-[70px]">
          Customer Transfer
        </p>
        <p className="px-3 text-[16px] font-[500] mt-[70px]"></p>
      </div>

      <div className="container ">
        {/* create product form */}
        <form className="">
          <div className=" grid p-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-8">
            <div className="-mx-3 mb-6 px-4 w-full">
              <div className="flex flex-wrap justify-center items-center -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Name
                  </label>
                  <input
                    className="appearance-none  block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="name"
                    disabled
                    type="text"
                    autoComplete="off"
                    // required
                    // placeholder="Customer Name"
                    name="name"
                    value={name || ''}
                    // onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Phone
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="phone"
                    type="text"
                    autoComplete="off"
                    disabled
                    required
                    placeholder="Customer Phone"
                    name="phone"
                    value={phone || ''}
                    // onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Address
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="address"
                    type="text"
                    autoComplete="off"
                    disabled
                    required
                    placeholder="Customer address"
                    name="address"
                    value={address || ''}
                    // onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className=" px-3 -mx-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Brands
                </label>
                {brands?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full  justify-center px-3 py-1 mb-6 md:mb-3 lg:mb-4 xl:mb-4 border"
                  >
                    <input
                      checked={
                        selectBrands
                          .map((item1) => item1.name)
                          .includes(item.name)
                          ? true
                          : false
                      }
                      autoComplete="off"
                      id="checkbox"
                      type="checkbox"
                      disabled
                      value={item.name}
                      //   onChange={handleCheck}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      className={`${isChecked(
                        item.name
                      )} w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                    >
                      {item.name}
                    </label>
                    <div className="flex  items-center w-full  justify-center px-3 ">
                      <label className="block px-4 uppercase tracking-wide text-gray-700 text-sm font-bold mb-2">
                        {t('admin.amount')}
                      </label>
                      <input
                        className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                        id="amount"
                        type="number"
                        autoComplete="off"
                        // required
                        disabled
                        // placeholder="Amount"
                        name="amount"
                        value={selectBrands
                          .filter((item1) => item1.name === item.name)
                          .map((itemAmount) => itemAmount.amount)}
                      />
                    </div>
                  </div>
                ))}

                <div>{`Items selected are: ${checkedItems}`}</div>
              </div>
              <div className="w-full mt-6 flex items-center justify-start pb-3 flex-wrap">
                {image && (
                  <img
                    src={update ? image : URL.createObjectURL(image)}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
            </div>

            <div className="flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-2/3 px-3 mb-4 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                  Status
                </label>
                <div className=" px-2 -mx-3">
                  {level &&
                    level.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-wrap justify-between items-center py-1"
                      >
                        <div className="flex">
                          <input
                            id="status"
                            autoComplete="off"
                            type="text"
                            disabled
                            value={item.status}
                            className="  p-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                        </div>
                        <div className="flex  justify-center items-center  py-2"></div>
                        <div>
                          <p>{item.updatedAt?.split(' ')[0]}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {level &&
              level.find((item, index) => item.status === 'Canceled') ? (
                <div>
                  <div className="w-full md:w-2/3 px-3 py-2 mb-4 md:mb-0 ">
                    {cancelList.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center rounded w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-2 lg:mb-2 xl:mb-2 border"
                      >
                        <input
                          id="bordered-radio-1"
                          checked={reason && reason === item ? true : false}
                          type="radio"
                          autoComplete="off"
                          value={item}
                          disabled
                          name="bordered-radio"
                          // eslint-disable-next-line no-sequences
                          // onChange={handleCancel}
                          className="w-4 h-4 text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>

                        <label
                          className={`${isChecked(
                            item
                          )} w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>

                  {/* <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Cancel Reason
                    </label>
                    <textarea
                      rows="3"
                      name="reasonDetail"
                      disabled
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write cancel reason here..."
                      value={reasonDetail || ''}
                    
                    ></textarea>
                  </div> */}
                </div>
              ) : null}
              <div className="w-full md:w-2/3 px-3  mt-4 mb-6 md:mb-0 ">
                <div className="w-full">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Old Engineer
                  </label>
                  <input
                    className="appearance-none block w-full bg-[#F1F2F7] text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-[#404041]"
                    id="engineer"
                    autoComplete="off"
                    type="text"
                    disabled
                    name="engineer"
                    value={oldEngName || ''}
                    // onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full md:w-2/3 px-3  mt-5 mb-6 md:mb-0 ">
                <div>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    New Enginner
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      required
                      //   value={engineer}
                      onChange={(e) => {
                        handleSelectEngineer(e)
                      }}
                    >
                      <option>Select Engineer</option>
                      {allEngineer &&
                        allEngineer.map((item, index) => (
                          <option key={index}>{item.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Total Amount of Contract
                </label>
                <input
                  name="totalPrice"
                  disabled
                  value={totalPrice || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Total Amount"
                  onChange={(e) => setTotalPrice(e.target.value)}
                ></input> */}
              </div>
              {/* <div className="w-full md:w-2/3 px-3  mt-4 mb-6 md:mb-0 ">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Noties
                </label>
                <textarea
                  rows="5"
                  name="noties"
                  disabled
                  value={noties || ''}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your noties here..."
                  // onChange={(e) => setNoties(e.target.value)}
                ></textarea>
              </div> */}
              <div>
                <br />

                <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0 ">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="mt-2 cursor-pointer text-[#F4AA8F] hover:text-[#fff] appearance-none bg-[#404041] hover:bg-[#222222] text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TransferCustomer
