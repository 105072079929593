import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  isEngineer: false, 
  engineer: null,   
  error: null,
  successMessage: null,
};

export const engineerReducer = createReducer(initialState, {
  LoadEngineerRequest: (state) => {
    state.isLoading = true
  },
  LoadEngineerSuccess: (state, action) => {
    state.isEngineer = true
    state.isLoading = false
    state.engineer = action.payload
  },
  LoadEngineerFail: (state, action) => {
    state.isLoading = false
    state.error = action.payload
    state.isEngineer = false
  },

  clearErrors: (state) => {
    state.error = null
  },
  clearMessages: (state) => {
    state.successMessage = null
  },
})
