import axios from 'axios'
import api from '../../api'

// get All Customers of an Admin
export const getAllCustomersAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllCustomersAdminRequest',
    })

    const { data } = await api.get(`/customer/get-all-customer`)
    dispatch({
      type: 'getAllCustomersAdminSuccess',
      payload: data.customers,
    })
  } catch (error) {
    dispatch({
      type: 'getAllCustomersAdminFailed',
      payload: error.response.data.message,
    })
  }
}

// delete customer of an admin
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteCustomerAdminRequest',
    })

    const { data } = await api.delete(`/customer/delete-customer-admin/${id}`, {
      withCredentials: true,
    })

    dispatch({
      type: 'deleteCustomerAdminSuccess',
      payload: data.message,
    })
  } catch (error) {
    dispatch({
      type: 'deleteCustomerAdminFailed',
      payload: error.response.data.message,
    })
  }
}
