import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  AiOutlineLeft,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
  AiOutlineRight,
} from 'react-icons/ai'
import { RxCross1 } from 'react-icons/rx'
import axios from 'axios'
import api from '../../api'
import { toast } from 'react-toastify'
import { getAllBranchs } from '../../redux/actions/branch'
import { getAllSellers } from '../../redux/actions/seller'
import ReactPaginate from 'react-paginate'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { getCurrentTime } from '../../utils/timeConvertor'

const itemsPerPage = 20

const AllEngineerings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { sellers } = useSelector((state) => state.seller)
  const { branches } = useSelector((state) => state.branches)
  const [sellerId, setSellerId] = useState('')
  const [openAdd, setOpenAdd] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [open, setOpen] = useState(false)

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [branch, setBranch] = useState('')
  const [branchId, setBranchId] = useState('')
  const [visible, setVisible] = useState(false)
  const [role, setRole] = useState('')
  const conponentPDF = useRef()
  const [allSellers, setAllSellers] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const [searchShow, setSearchShow] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  const adminList = ['Engineer', 'BranchAdmin', 'Recieptionist']

  useEffect(() => {
    dispatch(getAllSellers())
    dispatch(getAllBranchs())
    setAllSellers(sellers)
  }, [dispatch])

  useEffect(() => {
    setAllSellers(sellers)
  }, [sellers])

  const offset = currentPage * itemsPerPage
  const paginatedData = allSellers?.slice(offset, offset + itemsPerPage)

  const handleSearchChange = (e) => {
    const term = e.target.value
    setSearchTerm(term)

    const filteredProducts =
      sellers &&
      sellers.filter((person) => {
        return person.name.toLowerCase().includes(term.toLowerCase())
      })

    if (term === '') {
      setSearchShow(false)
      dispatch(getAllBranchs())
      setAllSellers(sellers)
    } else {
      setSearchShow(true)
      setAllSellers(filteredProducts)
    }
  }

  // const pageStyle = '@page { size: 1in 2in }';

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    pageStyle: `
      @page {
        size: A4 portrate;
        margin: 1cm;
      }
      @media print {
        body {
          margin: 1;
          padding: 1;
        }
      }
    `,
    documentTitle: 'All Engineers',
  })

  const handleSelect = (event) => {
    const value = event.target.value

    branches.filter((item) =>
      item.name === value ? setBranchId(item._id) : ''
    )
    setBranch(value)
  }

  var isChecked = (item) =>
    role.includes(item) ? 'checked-item' : 'not-checked-item'

  const handleSubmitPassword = async () => {
    if (sellerId) {
      await api
        .put(
          `/engineering/update-engineer-password/${sellerId}`,
          {
            password,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success('Password updated succesfully!')
          setPassword('')
          setOpen(false)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
    }
  }

  const handleSubmit = async () => {
    if (sellerId) {
      await api
        .put(
          `/engineering/update-engineering-info/${sellerId}`,
          {
            name,
            phone,
            role,
            branch,
            branchId,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success('Engineer info updated succesfully!')
          navigate('/admin/Engineers')
          dispatch(getAllSellers())
          setName('')
          setPhone('')
          setRole('')
          setPassword('')
          setBranch('')
          setBranchId('')
          setSellerId('')
          setOpenAdd(false)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
    } else {
      const currentDate = getCurrentTime()
      await api
        .post(`/engineering/create-engineering`, {
          name,
          password,
          phone,
          role,
          branch,
          branchId,
          createdAt: currentDate,
        })
        .then((res) => {
          toast.success('Engineer info added succesfully!')
          navigate('/admin/Engineers')
          setName('')
          setPhone('')
          setRole('')
          setPassword('')
          setBranch('')
          setBranchId('')
          setSellerId('')
          dispatch(getAllSellers())
          setOpenAdd(false)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
    }
  }

  return (
    <div className="container mt-[65px]  px-3 mx-auto">
      <div className="mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-3 gap-4">
        <div className="rounded  border-gray-300 ">
          <div className="relative flex w-full items-stretch">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto ltr:rounded-l rtl:rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search by name"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div
              className="relative z-[2] flex items-center ltr:rounded-r rtl:rounded-l bg-[#222222]    px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  // fillrule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  // cliprule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="rounded ">
          <div className="flex justify-end items-center w-full">
            <div className="relative px-2 flex justify-end w-1/5">
              <button
                className="text-[#F4AA8F] hover:text-[#fff]  appearance-none bg-[#404041] hover:bg-[#222222] text-center block px-4 h-[35px] border border-gray-300 rounded"
                onClick={generatePDF}
              >
                {t('admin.print')}{' '}
              </button>
            </div>
            <div
              onClick={() => {
                setOpenAdd(true)
                setAddNew(true)
                setOpen(false)
              }}
              className="items-stretch mr-2 hover:text-[#fff] "
            >
              <button
                //  to="/dashboard-register-customer"
                className="w-full flex items-center px-1 h-[35px]  rounded bg-[#F4AA8F] hover:bg-[#413632]  "
              >
                <div className="p-1">
                  <span className="text-black text-[12px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[600] flex items-center hover:text-[#fff] ">
                    <AiOutlinePlus
                      size={20}
                      color={200}
                      className="mr-1 items-center"
                    />
                    {t('admin.add_engineer')}{' '}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-b py-1"></div>

      {/* Table */}
      <div ref={conponentPDF} className="container  mx-auto">
        <div className="mx-auto grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center px-3 py-3 ">
            <p className=" text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] rtl:xl:text-[20px] font-[500]">
              {t('admin.all_engineer')}{' '}
            </p>
          </div>
          <div className="flex justify-end items-center px-3 py-3 ">
            <span className="text-black text-[12px] md:text-[14px] lg:text-[15px] xl:text-[16px]  font-[500]">
              ({allSellers?.length}) {t('customer.enginner')}{' '}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle  md:px-2 lg:px-2">
              <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#F1F2F7] dark:bg-gray-800">
                    <tr>
                      {/* <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>ID</span>
                        </div>
                      </th> */}
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.name')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.phone')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('customer.branch')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.role')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>{t('admin.create_at')}</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4 text-md  font-medium text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        <div className="flex items-center justify-end px-3 gap-x-3">
                          <span>{t('admin.actions')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {paginatedData?.map((item, index) => (
                      <tr key={index}>
                        {/* <td className="px-4 py-42 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item._id}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td> */}
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.phone}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.branch}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.role}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                          <div className="inline-flex items-center gap-x-3">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <h2 className="font-medium text-gray-800 dark:text-white ">
                                  {item.createdAt?.split(' ')[0]}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                          <div className="flex items-center justify-end px-3 gap-x-6 ">
                            <button
                              onClick={() => {
                                setOpenAdd(true)
                                setSellerId(item._id)
                                setName(item.name)
                                setPhone(item.phone)
                                setRole(item.role)
                                const isItemExists =
                                  sellers &&
                                  sellers.find((i) => i._id === item._id)
                                if (isItemExists) {
                                  setPassword(isItemExists.password)
                                }
                                setBranch(item.branch)
                                setAddNew(false)
                                setOpen(false)
                                sellers &&
                                  sellers.filter((itemEng) =>
                                    itemEng._id === item._id
                                      ? setBranchId(itemEng.branchId)
                                      : ''
                                  )
                              }}
                              className="text-[#F4AA8F] transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-[#404041] focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <ReactPaginate
          breakLabel={'...'}
          nextLabel={<AiOutlineRight size="20" className="rtl:-scale-x-100" />}
          previousLabel={
            <AiOutlineLeft size="20" className="rtl:-scale-x-100" />
          }
          pageRangeDisplayed={4}
          pageCount={Math.ceil(sellers?.length / itemsPerPage)}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
          shape="rounded"
        />
      </div>

      {/* Start */}
      {openAdd && (
        <div>
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div
              role="alert"
              className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
            >
              <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                <h1 className="text-gray-800 text-md font-bold tracking-normal leading-tight mb-6">
                  {sellerId
                    ? t('admin.update_engineer')
                    : t('admin.add_engineer')}
                </h1>
                <label
                  htmlFor="name"
                  className="block uppercase tracking-wide text-gray-700 text-md  mb-2 "
                >
                  {t('admin.name')}
                </label>
                <input
                  id="name"
                  className="mb-5 mt-2 px-2  text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="Engineering Name"
                  type="text"
                  required
                  name="name"
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                />
                <label
                  htmlFor="email2"
                  className="block uppercase tracking-wide text-gray-700 text-md  mb-2"
                >
                  {t('customer.phone')}
                </label>
                <input
                  id="phone"
                  className="mb-5 mt-2 px-2  text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="phone Number"
                  type="text"
                  required
                  name="phone"
                  value={phone || ''}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {addNew && (
                  <div className="mt-1 relative">
                    <label
                      htmlFor="email2"
                      className="block uppercase tracking-wide text-gray-700 text-md  mb-2"
                    >
                      {t('admin.new_password')}
                    </label>
                    <input
                      type={visible ? 'text' : 'password'}
                      name="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    />
                    {visible ? (
                      <AiOutlineEyeInvisible
                        className="absolute ltr:right-2 rtl:left-2 top-8 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute ltr:right-2 rtl:left-2 top-8 cursor-pointer"
                        size={25}
                        onClick={() => setVisible(true)}
                      />
                    )}
                  </div>
                )}

                <div>
                  <label className="block uppercase tracking-wide text-gray-700 text-md  mb-2">
                    {t('customer.branch')}
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      required
                      value={branch}
                      onChange={(e) => {
                        handleSelect(e)
                      }}
                    >
                      <option>{t('admin.select_branch')} </option>
                      {branches &&
                        branches?.map((item, index) => (
                          <option key={index}>{item.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute  inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <label className="block mt-4 uppercase tracking-wide text-gray-700 text-md  mb-2">
                  {t('admin.role')}
                </label>
                {adminList?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center rounded w-full md:w-2/3 justify-center px-3 py-1 mb-3 md:mb-2 lg:mb-2 xl:mb-2 border"
                  >
                    <input
                      id="bordered-radio-1"
                      name="bordered-radio"
                      type="radio"
                      value={item || ''}
                      // checked={reason && reason === item ? true : null}
                      // eslint-disable-next-line no-sequences
                      onChange={(e) => setRole(e.target.value)}
                      checked={role && role.includes(item) ? true : false}
                      className="w-4 h-4 text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    ></input>

                    <label
                      className={`${isChecked(
                        item
                      )} w-full py-2 px-2 text-sm font-medium text-gray-900 dark:text-gray-300`}
                    >
                      {item}
                    </label>
                  </div>
                ))}

                <div className="flex items-center justify-center w-full mt-6">
                  <button
                    className="focus:outline-none transition duration-150 ease-in-out hover:bg-[#F4AA8F] bg-[#404041] hover:text-[#404041] rounded text-white px-8 py-2 text-md"
                    onClick={() => handleSubmit()}
                  >
                    {t('admin.save')}{' '}
                  </button>
                  <button
                    className="focus:outline-none ml-3 rtl:mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-md"
                    onClick={() => {
                      setOpenAdd(false)

                      setName('')
                      setPhone('')
                      setPassword('')
                      setBranch('')
                      setBranchId('')
                      setSellerId('')
                    }}
                  >
                    {t('admin.cancel')}{' '}
                  </button>
                  {addNew && addNew ? null : (
                    <button
                      className="w-full flex items-center hover:text-black text-[#fff] rounded bg-gray-600 hover:bg-gray-300 focus:outline-none ml-3 transition duration-150 border rounded px-4 py-2 text-md"
                      onClick={() => {
                        setOpen(true)
                      }}
                    >
                      {t('admin.change_password')}{' '}
                    </button>
                  )}
                </div>
                <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out">
                  <RxCross1
                    size={20}
                    onClick={() => {
                      setOpenAdd(false)
                      setName('')
                      setPhone('')
                      setPassword('')
                      setBranch('')
                      setBranchId('')
                      setSellerId('')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {open && (
        <div>
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div
              role="alert"
              className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
            >
              <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                <h1 className="text-gray-800 text-md font-bold tracking-normal leading-tight mb-6">
                  {t('admin.change_password')}{' '}
                </h1>
                <div className="mt-1 relative">
                  <label
                    htmlFor="email2"
                    className="block uppercase tracking-wide text-gray-700 text-md mb-2"
                  >
                    {t('admin.new_password')}{' '}
                  </label>
                  <input
                    type={visible ? 'text' : 'password'}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mb-5 mt-2 px-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  />
                  {visible ? (
                    <AiOutlineEyeInvisible
                      className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute ltr:right-2 rtl:left-2 top-10 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>

                <div className="flex items-center justify-center w-full mt-6">
                  <button
                    className="focus:outline-none transition duration-150 ease-in-out hover:bg-[#F4AA8F] bg-[#404041] hover:text-[#404041] rounded text-white px-8 py-2 text-md"
                    onClick={() => handleSubmitPassword()}
                  >
                    {t('admin.save')}{' '}
                  </button>
                  <button
                    className="focus:outline-none ml-3 rtl:mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-md"
                    onClick={() => {
                      setOpen(false)
                      setPassword('')
                    }}
                  >
                    {t('admin.cancel')}{' '}
                  </button>
                </div>
                <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out">
                  <RxCross1
                    size={20}
                    onClick={() => {
                      setOpen(false)
                      setPassword('')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AllEngineerings
