import axios from 'axios'
import api from '../../api'

//create Branch
export const createBranch = (newBranch) => async (dispatch) => {
  try {
    dispatch({
      type: 'branchCreateRequest',
    })

    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    const { data } = await api.post(`/branch/create-branch`, newBranch, config)
    dispatch({
      type: 'branchCreateSuccess',
      payload: data.branch,
    })
  } catch (error) {
    dispatch({
      type: 'branchCreateFail',
      payload: error.response.data.message,
    })
  }
}

// get All Categories of a shop
export const getBranchID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getBranchIdRequest',
    })

    const { data } = await api.get(`/branch/get-branchId/${id}`)
    dispatch({
      type: 'getBranchIdSuccess',
      payload: data.categories,
    })
  } catch (error) {
    dispatch({
      type: 'getBranchIdFailed',
      payload: error.response.data.message,
    })
  }
}

// get all Branchs
export const getAllBranchs = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllBranchsRequest',
    })
    const { data } = await api.get(`/branch/admin-all-branches`, {
      withCredentials: true,
    })


    dispatch({
      type: 'getAllBranchsSuccess',
      payload: data.branches,
    })
  } catch (error) {
    dispatch({
      type: 'getAllBranchsFailed',
      payload: error.response.data.message,
    })
    console.error('Error fetching branches:', error)
  }
}

// get all Categories
// export const getAllCategoryById = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: 'getAllCategoryRequest',
//     });

//     const { data } = await axios.get(`${server}/category/get-category/${id}`);
//     dispatch({
//       type: 'getAllCategorySuccess',
//       payload: data.category,
//     });
//   } catch (error) {
//     dispatch({
//       type: 'getAllCategoryFailed',
//       payload: error.response.data.message,
//     });
//   }
// };

// update Category
export const updateCategory = (category) => async (dispatch) => {
  try {
    dispatch({
      type: 'updateCategoryRequest',
    })

    const { data } = await api.put(
      `/category/update-category-name/${category.id}`,
      category,
      { withCredentials: true }
    )
    dispatch({
      type: 'updateCategorySuccess',
      payload: {
        successMessage: 'Category updated succesfully!',
        category: data,
      },
    })
  } catch (error) {
    dispatch({
      type: 'updateCategoryFailed',
      payload: error.response.data.message,
    })
  }
}
