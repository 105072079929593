import axios from 'axios'
import api from '../../api'

// create customer
export const createCustomer = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: 'customerCreateRequest',
    })

    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    const { data } = await api.post(
      `/customer/create-customer`,
      newForm,
      config
    )
    dispatch({
      type: 'customerCreateSuccess',
      payload: data.customer,
    })
  } catch (error) {
    dispatch({
      type: 'customerCreateFail',
      payload: error.response.data.message,
    })
  }
}

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllProductsShopRequest',
    })

    const { data } = await api.get(
      `/product/get-all-products-shop/${id}`
    )
    dispatch({
      type: 'getAllProductsShopSuccess',
      payload: data.products,
    })
  } catch (error) {
    dispatch({
      type: 'getAllProductsShopFailed',
      payload: error.response.data.message,
    })
  }
}

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'deleteProductRequest',
    })

    const { data } = await api.delete(
      `/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    )

    dispatch({
      type: 'deleteProductSuccess',
      payload: data.message,
    })
  } catch (error) {
    dispatch({
      type: 'deleteProductFailed',
      payload: error.response.data.message,
    })
  }
}

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllProductsRequest',
    })

    const { data } = await api.get(`/product/get-all-products`)
    dispatch({
      type: 'getAllProductsSuccess',
      payload: data.products,
    })
  } catch (error) {
    dispatch({
      type: 'getAllProductsFailed',
      payload: error.response.data.message,
    })
  }
}

// get all products
export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'getProductRequest',
    })

    const { data } = await api.get(`/product/get-product/${id}`)
    dispatch({
      type: 'getProductSuccess',
      payload: data.product,
    })
  } catch (error) {
    dispatch({
      type: 'getProductFailed',
      payload: error.response.data.message,
    })
  }
}
