import axios from 'axios'

const local = 'https://api.delos-ops.com'
// const local = 'http://localhost:5000'

const api = axios.create({
  baseURL: `${local}/api/v2`,
})

// const api = axios.create({
//   baseURL:
//     process.env.NODE_ENV === 'production'
//       ? 'https://api.delos-ops.com/api/v2'
//       : 'http://localhost:5000/api/v2',
// })

export default api
