import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { AiOutlinePlus } from 'react-icons/ai'
import { Button } from '@material-ui/core'
import axios from 'axios'
import api from '../../api'
import { toast } from 'react-toastify'
import { BsEye } from 'react-icons/bs'
import { getAllCustomersAdmin } from '../../redux/actions/admin'
import { getAllCustomersEng } from '../../redux/actions/customer'

import Cookies from 'js-cookie'
import { getAllCustomersBranch } from '../../redux/actions/branchCustomer'
import { useTranslation } from 'react-i18next'

const AllStores = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let { state } = useLocation()
  const { customers } = useSelector((state) => state.customers)
  const { branchCustomers } = useSelector((state) => state.customerBranch)
  const { engineer } = useSelector((state) => state.engineer)
  const engBranchId = Cookies.get('branchId')
  const [allCustomers, setAllCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchShow, setSearchShow] = useState(false)

  useEffect(() => {
    if (engineer.role === 'BranchAdmin') {
      // const isItemExists =
      //   branchCustomers &&
      //   branchCustomers.filter(
      //     (item) => item.levels[item.levels?.length - 1]?.status === 'Storage'
      //   );
      // setAllCustomers(isItemExists);
      if (state.sellerId !== '') {
        const isItemExists =
          branchCustomers &&
          branchCustomers.filter((item) => {
            return (
              item.levels[item.levels?.length - 1]?.status === 'Storage' &&
              item.engineeringId === state.sellerId
            )
          })
        setAllCustomers(isItemExists)
      } else {
        const isItemExists =
          branchCustomers &&
          branchCustomers.filter((item) => {
            return item.levels[item.levels?.length - 1]?.status === 'Storage'
          })
        setAllCustomers(isItemExists)
      }
    } else {
      const isItemExists =
        customers &&
        customers.filter(
          (item) => item.levels[item.levels?.length - 1]?.status === 'Storage'
        )
      setAllCustomers(isItemExists)
    }
  }, [branchCustomers, customers])

  useEffect(() => {
    dispatch(getAllCustomersEng(engineer._id))
    dispatch(getAllCustomersBranch(engBranchId))
  }, [dispatch, engineer._id, engBranchId])

  const handleSearchChange = (e) => {
    const term = e.target.value
    setSearchTerm(term)

    const filteredProducts =
      allCustomers &&
      allCustomers.filter((person) => {
        return (
          person.name.toLowerCase().includes(term.toLowerCase()) ||
          person.phone.toLowerCase().includes(term.toLowerCase())
        )
      })

    if (term === '') {
      setSearchShow(false)
      dispatch(getAllCustomersEng(engineer._id))
      dispatch(getAllCustomersBranch(engBranchId))
    } else {
      setSearchShow(true)
      setAllCustomers(filteredProducts)
    }
  }

  const handleDelete = async (id) => {
    await api
      .delete(`/customer/delete-customer-admin/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success(res.data.message)
      })

    dispatch(getAllCustomersAdmin())
  }

  const columns = [
    {
      field: 'id',
      headerName: 'User ID',
      minWidth: 140,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'name',
      headerName: 'name',
      minWidth: 130,
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'text',
      minWidth: 100,
      flex: 0.4,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'engineering',
      headerName: 'Engineering',
      type: 'text',
      minWidth: 110,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      minWidth: 100,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'joinedAt',
      headerName: 'joinedAt',
      type: 'text',
      minWidth: 100,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '  ',
      flex: 0.3,
      minWidth: 60,
      headerName: 'Edit',
      type: 'text',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/register/customer/${params.id}`}>
              <Button>
                <AiOutlinePlus size={22} className="" fill="#F4AA8F" />
              </Button>
            </Link>
          </>
        )
      },
    },
    {
      field: '  ',
      flex: 0.3,
      minWidth: 60,
      headerName: 'View',
      type: 'text',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/customer/details/${params.id}`}>
              <Button>
                <BsEye size={22} className="" fill="#F4AA8F" />
              </Button>
            </Link>
          </>
        )
      },
    },
  ]

  const row = []
  allCustomers &&
    allCustomers.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        phone: item.phone,
        engineering: item.engineering,
        status: item.levels[item.levels?.length - 1]?.status,
        joinedAt: item.createdAt?.split(' ')[0],
      })
    })

  return (
    // <>
    //   {isLoading ? (
    //     <Loader />
    //   ) : (
    <div className="container">
      <div className="relative  mt-[65px] mr-3 flex justify-between items-center border-b p-1 border-gray-200">
        <div className="flex justify-between items-center w-1/3">
          <p className="px-3 text-[10px] md:text-[16px] lg:text-[16px] xl:text-[16px] font-[600]">
            {t('main.store')}
          </p>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="relative flex w-2/3 items-stretch">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#F4AA8F] focus:text-neutral-700  focus:outline-none "
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div
              className="relative z-[2] flex items-center ltr:rounded-r rtl:rounded-l bg-[#222222]   px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#F4AA8F] shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  // fillrule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  // cliprule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div className="items-stretch mr-2">
            <div className="p-2">
              <span className="text-black text-[10px] md:text-[14px] lg:text-[15px] xl:text-[15px]  font-[600] flex items-center">
                ({allCustomers?.length}) {t('main.customers')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center px-2 mr-3">
        <div className="w-full min-h-[45vh] mt-3  bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={15}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      </div>
    </div>
    //   )}
    // </>
  )
}

export default AllStores
