import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsEye, BsPencilSquare } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomersEng } from '../../redux/actions/customer';
import { BiTransfer } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const CustomerCard = ({ data }) => {
  const { engineer } = useSelector((state) => state.engineer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllCustomersEng(engineer._id));
  }, [dispatch, engineer._id]);

  return (
    <>
      <div className={`rounded bg-[#F1F2F7] h-auto`}>
        <Link to={`/dashboard/customer/details/${data._id}`}>
          <div className="border-b border-gray-200 rounded-t-lg pb-3cd back  px-3 py-3 bg-[#404041]">
            <h1 className="lg:text-l text-base leading-4 font-semibold lg:leading-4 text-gray-300">
              {data.name}
            </h1>
          </div>
        </Link>
        <div className="py-2 px-2">
          <p className="text-base leading-4 text-gray-800">
            {t('customer.address')}: {data.address}
          </p>
        </div>
        <div className="py-1 px-2">
          <p className="text-base leading-4 text-gray-800">
            {t('customer.phone')}: {data.phone}
          </p>
        </div>
        <div className="py-1 px-2">
          <p className="text-base leading-4 text-gray-800">
            {t('customer.fileNo')}: {data.fileNo}
          </p>
        </div>
        <div className="flex justify-between items-center w-full py-2 px-2">
          <div className="flex">
            <p className="text-md  leading-4 text-gray-800">
              {t('customer.status')}:{' '}
              {data.levels[data.levels.length - 1]?.status}
            </p>
          </div>
          <div
            className={`flex justify-center items-center rounded-full w-5 h-5  `}
            style={{
              backgroundColor: data.levels[data.levels.length - 1]?.color,
            }}
          ></div>
        </div>
        <div className="py-1 px-2">
          <p className="text-md leading-4 text-gray-800">
            {t('customer.date')}: {data.createdAt?.split(' ')[0]}
          </p>
        </div>
        {engineer && engineer.role === 'BranchAdmin' ? (
          <div className="py-2 px-2">
            <p className="text-md leading-4 text-gray-800">
              {t('customer.enginner')}: {data.engineering}
            </p>
          </div>
        ) : null}

        <div className="flex-wrap px-3 py-1 -mx-3">
          <div className="w-full  px-3">
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-sm text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option value="" className="block border pb-2">
                  Brands
                </option>
                {data.selectBrands &&
                  data.selectBrands.map((item, index) => (
                    <option key={index} disabled>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className="flex justify-center items-center w-full py-2">
              <div className="flex w-2/3  justify-center items-center">
                <Link to={`/dashboard/customer/details/${data._id}`}>
                  <BsEye size={22} className="color-gray-800" fill="#413632" />
                </Link>
              </div>

              {/* <div className="flex w-2/3  justify-center items-center">
                <Link to={`/dashboard/register/customer/${data._id}`}>
                  <BsPencilSquare size={22} className="" fill="#F4AA8F" />
                </Link>
              </div> */}

              {engineer && engineer.role === 'BranchAdmin' ? (
                <>
                  <div className="flex w-2/3  justify-center items-center">
                    <Link to={`/dashboard/transfer/${data._id}`}>
                      <BiTransfer size={28} className="" fill="#F4AA8F" />
                    </Link>
                  </div>
                  {/* <div className="flex w-2/3  justify-center items-center">
                    <Link to={`/dashboard/customer/${data._id}`}>
                      <BsPencilSquare size={22} className="" fill="#F4AA8F" />
                    </Link>
                  </div> */}
                  {/* <div className="flex w-2/3  justify-center items-center">
                    <Link to={`/dashboard/register/customer/${data._id}`}>
                      <BsPencilSquare size={22} className="" fill="#F4AA8F" />
                    </Link>
                  </div> */}
                </>
              ) : (
                <div className="flex w-2/3  justify-center items-center">
                  <Link to={`/dashboard/register/customer/${data._id}`}>
                    <BsPencilSquare size={22} className="" fill="#F4AA8F" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {showModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex  items-center p-2 min-h-screen py-2">
              <div className="relative w-full lg:w-full xl:w-full max-w-lg mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex  flex items-center justify-center">
                  <div className="flex  items-center justify-center  flex-none w-12 h-12 bg-[#413632] rounded-full">
                    <BsInfoCircleFill size={25} fill="#F4AA8F" />
                  </div>
                  <div className="mt-2 p-3 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Do you want to delete this customer ?
                    </h4>

                    <div className="items-center gap-2 mt-2 p-2 sm:flex">
                      <button
                        className="w-2/3 mt-1 p-1 flex-1 text-white bg-[#C51605] rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleDelete(data._id)}
                      >
                        Delete
                      </button>
                      <button
                        className="w-2/3 mt-1 p-1 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null} */}
    </>
  );
};

export default CustomerCard;
