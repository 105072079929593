import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Loader from '../components/Layout/Loader'

const EngineerProtectRoute = ({ children }) => {
  const engBranchId = Cookies.get('branchId')
  const { isLoading, isEngineer } = useSelector((state) => state.engineer)
  console.log('engineer route', isEngineer, engBranchId)

  if (isLoading === true) {
    return <Loader />
  } else {
    if (!isEngineer) {
      return <Navigate to={`/engineer-login/${engBranchId}`} replace />
    }

    return children
  }
}

export default EngineerProtectRoute
