// import axios from 'axios'
import api from '../../api'
// import { server } from '../../server'

//load seller
export const loadEngineer = (branchId) => async (dispatch) => {
  try {
    dispatch({
      type: 'LoadEngineerRequest',
    })
    const { data } = await api.get(`/engineering/getEngineer/${branchId}`, {
      withCredentials: true,
    })

    dispatch({
      type: 'LoadEngineerSuccess',
      payload: data.engineer,
    })
  } catch (error) {
    dispatch({
      type: 'LoadEngineerFail',
      payload:
        error.response?.data?.message || error.message || 'An error occurred',
    })
    // dispatch({
    //   type: 'LoadEngineerFail',
    //   payload: error.response.data.message,
    // })
  }
}
