// export const server = 'http://localhost:5000/api/v2'

// export const backend_url = 'http://localhost:5000/'

// export const backend_url_img = 'http://localhost:5000/uploads';

// export const server = 'https://api.delos-ops.com/api/v2'
//
export const backend_url = 'https://api.delos-ops.com/api/'

// export const backend_url_img = 'https://delos-ops.com/api/uploads';
